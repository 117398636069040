<template>
  <div>
    <div class="curtain"></div>
    <div id="login-panel">
      <h2>Login</h2>
      <div class="field">
        <label for="email">E-mail address</label>
        <input type="text" id="email" v-model="form.email" placeholder="email">
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="form.password" placeholder="Password">
      </div>

      <button 
      class="mb-2"
      v-on:click="performLogin()"
      >
        <span v-if="!waiting">Log in</span>
        <span v-if="waiting">Logging in...</span>
      </button>
      <button
      class="secondary"
      v-if="!waiting"
      v-on:click="$parent.loginOpen = false"
      >Cancel</button>
      <p v-if="errors.length > 0"></p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  components: {
  },
  props: {
    user: Object
  },
  data: function(){
    return {
      waiting: false,
      errors: [],
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    performLogin() {
      let self = this;

      self.waiting = true;
      axios.get('/sanctum/csrf-cookie').then(() => {
        axios.post('/login', {
          email: self.form.email,
          password: self.form.password,
        }).then(() => {
          self.$store.state.loggedin = true;
          self.waiting = false;
        }).catch(error => console.log(error)); // credentials didn't match
      });
    },
  }
}
</script>
<style lang="scss" scoped>
  .curtain {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    content: '';
    z-index: 98;
  }

  #login-panel {
    position: fixed;
    top: 50vh;
    left: 50%;
    width: 80%;
    max-width: 380px;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    box-shadow: var(--shadow);
    padding: 20px;
    text-align: left;
    z-index: 99;

    h2 {
      margin: 0 0 20px 0;
    }

    .field {
      margin-bottom: 20px;

      label {
        display: block;
      }

      input {
        padding: 0.4em 0.5em;
        width: 100%;
      }
    }

    button {
      width: 100%;
      padding: 0.4em 0.5em;
    }

  }
</style>