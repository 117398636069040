<template>
  <div class="search content">
    <div class="searchbox">
      <label for="searchterm">{{ $translate('search_by_serialnumber') }}</label>
      <input type="text" id="searchterm" v-model="searchterm" placeholder="Serial number">
    </div>
    <ul class="results table-like mt-2">
      <li
      class="tablerow-like"
      data-cellcount="2"
      v-for="(productarticleinstance, i) in productarticleinstances"
      v-bind:key="i"
      >
        <div class="cell-like">
          {{ productarticleinstance.serialnumber }}
        </div>
        <div class="cell-like">
          <router-link class="button mt-2" :to="'/u/' + productarticleinstance.serialnumber ">Show<span v-if="$store.state.loggedin"> and manage</span></router-link>
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Search',
  components: {
  },
  data: function(){
    return {
      searchterm: '',
      productarticleinstances: [],
      loading: false,
      cooldown: false
    }
  },
  methods: {
    sendSearch(){
      let self = this;
      axios.post(process.env.VUE_APP_API_URL + 'serialnumber/search', {
        searchterm: self.searchterm,
      }).then((response) => {
        self.loading = false;
        self.productarticleinstances = response.data.productarticleinstances;
      }).catch(error => console.log(error)); // credentials didn't match
    }
  },
  watch: {
    searchterm: function(){
      let self = this;
      clearTimeout(self.cooldown);
      var ms = 500;
      self.loading = true;
      self.cooldown = setTimeout(function() {
        self.sendSearch();
      }, ms);
    }
  },
}
</script>
<style lang="scss" scoped>
.search {
  padding-top: 80px;

  label {
    margin-bottom: 0.5em;
    display: block;
  }

  input {
    font-size: 1.45em;
    text-align: center
  }
}
</style>
