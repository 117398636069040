import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Show from '../views/Show.vue'
import Search from '../views/Search.vue'
import ProductArticleInstanceList from '../views/ProductArticleInstanceList.vue'
import axios from 'axios'
import store from '../store'

Vue.use(VueRouter)

function loadBootupData(from, to, next){
  if( !store.translations ){
    axios.get(process.env.VUE_APP_API_URL + 'app/boot')
    .then(function(response){
      store.translations = response.data.translations;
      next();
    });
  } else {
    next();
  }
  
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'All',
    component: ProductArticleInstanceList,
    beforeEnter: loadBootupData
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    beforeEnter: loadBootupData
  },
  {
    path: '/u/:serialnumber',
    name: 'Show',
    component: Show,
    beforeEnter: loadBootupData
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
