<template>
  <div class="">
    <div class="product-article-instance-list">
      <div
        class="single-article-instance content"
        v-for="(articleInstance, i) in productArticleInstances"
        v-bind:key="i"
        >
        <h1>{{ articleInstance.serialnumber }}</h1>
        <ul class="table-like">
          <li 
          class="tablerow-like"
          data-cellcount="2"
          >
            <div class="cell-like">Uptime</div>
            <div class="cell-like">{{ articleInstance.uptime.hours }}h @ {{ articleInstance.uptime.created_at }}</div>
          </li>
          <li 
          class="tablerow-like"
          data-cellcount="2"
          >
            <div class="cell-like">Attached machine serial number:</div>
            <div class="cell-like">{{ articleInstance.machine_serialnumber }}</div>
          </li>
        </ul>
        <router-link class="button mt-2" :to="'/u/' + articleInstance.serialnumber ">Show<span v-if="$store.state.loggedin"> and manage</span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProductArticleInstanceList',
  data: function(){
    return {
      productArticleInstances: {}
    }
  },
  methods: {
    getProductArticleInstances()
    {
      let self = this;
      axios.get(process.env.VUE_APP_API_URL + 'app/index')
      .then(function(response){
        self.productArticleInstances = response.data.productArticleInstances;
      });
    }
  },
  mounted: function(){
    this.getProductArticleInstances();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product-article-instance-list {
  padding-top: 80px;

  .single-article-instance {
    margin-bottom: 30px;
    padding: 20px 10px;
  }
}
</style>
