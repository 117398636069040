import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN;

Vue.prototype.$translate = function (x) {
  if( !this.$store.translations ){
    return x;
  }
  if( this.$store.translations[x] ){
    return this.$store.translations[x];
  } else {
    return x;
  }
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
