<template>
  <div class="logbook">
    <div class="entry" v-for="(entry,i) in logbookentries" v-bind:key="i">
      <p>{{ entry.entry }}</p>
      <div class="footer">
        <p class="date">{{ entry.created_at }}</p>
        <p class="user" v-if="$store.state.loggedin">{{ entry.user.name }}</p>
      </div>
    </div>

    <div 
    class="compose"
    v-if="$store.state.loggedin"
    >
      <div class="inner">
        <h3
        v-on:click="composerOpen = !composerOpen"
        >{{ $translate('logbookentry_add_new') }}</h3>
        <textarea
        class="mt-2"
        v-if="composerOpen"
        v-model="newEntry.body"
        ></textarea>
        <button
        v-if="composerOpen"
        v-on:click="storeNewLogbookEntry"
        v-bind:disabled="newEntry.body.length === 0"
        >Save</button>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',
  components: {
  },
  props: {
    articleInstance: {},
    publicLogbookentries: {}
  },
  data: function(){
    return {
      composerOpen: false,
      logbookentries: {},
      newEntry: {
        articleInstanceId: this.articleInstance.id,
        body: ''
      }
    }
  },
  methods: {
    getLogbookData(){
      let self = this;
      axios.get(process.env.VUE_APP_API_URL + 'serialnumber/' + this.$route.params.serialnumber + '/logbook')
      .then(function(response){
        self.logbookentries = response.data.logbookentries;
      });
    },
    storeNewLogbookEntry(){
      let self = this;
      if( self.newEntry.body.length === 0 ){
        return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'serialnumber/' + this.$route.params.serialnumber + '/logbook', {
        entry: self.newEntry,
      }).then(() => {
        self.getLogbookData();
        self.resetNewEntry();
      }).catch(error => console.log(error));
    },
    resetNewEntry(){
      this.newEntry = {
        articleInstanceId: this.articleInstance.id,
        body: ''
      }
      this.composerOpen = false;
    }
  },
  mounted: function(){
    this.logbookentries = this.publicLogbookentries;
    this.getLogbookData();
  }
}
</script>
<style lang="scss" scoped>
  .logbook {
    padding-bottom: 90px;
  }

  .entry {
    margin: 10px;
    border-bottom: 1px solid var(--grey);
    text-align: left;
    white-space: pre-line;
    background: #fff;
    border: 1px solid var(--grey);
    position: relative;
    margin-top: 40px;

    > p {
      padding: 0 10px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      font-size: 0.85em;
      background: var(--grey);
      color: #fff;
      padding: 0 10px;

      p {
        margin: 5px 0 5px;
      }
    }

  }

  .compose {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 10px;
    right: 10px;

    .inner {
      background: var(--blue);
      color: #fff;
      box-shadow: var(--shadow);
      padding: 10px 10px;
    }

    h3 {
      margin: 0;
    }

    textarea {
      width: 100%;
      min-height: 6em;
      display: block;
      margin-bottom: 0.5em;
    }

    button {
      width: 100%;
    }

  }
</style>