<template>
  <div class="show">
    <div class=""
    v-if="articleInstance"
    >
    <!-- {{ articleInstance.productarticle.productmodeltype.productmodel.productgrouptype }} -->
      <div 
      class="header"
      v-bind:style="headerStyle"
      >
        <h1>{{ articleInstance.productarticle.productmodeltype.productmodel.productgrouptype.productgroup.name }}</h1>
        <p>{{ articleInstance.productarticle.productmodeltype.productmodel.productgrouptype.productgroup.productfamily.productcategory.name }}</p>

        <Operationalstatus 
        :status="operationalstatus"
        @reload="getArticleInstance"
        />
      </div>
      <div class="content">
        <ul class="tabs">
          <li 
          v-on:click="selectTab('info')"
          v-bind:class="{'current' :  currentTab === 'info'}"
          >
            Info
          </li>
          <li 
          v-on:click="selectTab('specification')"
          v-bind:class="{'current' :  currentTab === 'specification'}"
          >
            Specification
          </li>
          <li 
          v-on:click="selectTab('logbook')"
          v-bind:class="{'current' :  currentTab === 'logbook'}"
          >
            Logbook
          </li>
        </ul>

        <div 
        class="tab info"
        v-if="currentTab === 'info'"
        >
        
          <ul class="table-like mt-2 mb-2">
            <li
            class="tablerow-like"
            data-cellcount="2"
            >
              <div class="cell-like">Initial deployment date:</div>
              <div class="cell-like">{{ specification.articleinstance.initial_deployment }}</div>
            </li>
            <li
            class="tablerow-like"
            data-cellcount="2"
            >
              <div class="cell-like" v-on:click="expandUptimeHistory = !expandUptimeHistory"><span class="app-link">Uptime:</span></div>
              <div class="cell-like" v-on:click="uptimeOpen = !uptimeOpen">
                {{ uptime.hours }}h @ {{ uptime.created_at }} <Pencil class="svg-blue" />
              </div>
            </li>
            <li class="contracted" v-bind:class="{'expanded' : expandUptimeHistory }">
              <ul class="table-like">
                <li
                class="tablerow-like"
                v-for="(uptime, i) in uptimeHistory"
                v-bind:key="i"
                data-cellcount="2"
                >
                  <div class="cell-like">{{ uptime.hours }} h</div>
                  <div class="cell-like">{{ uptime.created_at }}</div>
                </li>
              </ul>
              
            </li>
            <li
            class="tablerow-like"
            data-cellcount="2"
            >
              <div class="cell-like">Estimated uptime hours per year:</div>
              <div class="cell-like" v-on:click="estimatedYearlyUptimeOpen = !estimatedYearlyUptimeOpen">
                {{ specification.articleinstance.estimated_yearly_uptime }}h
                <Pencil class="svg-blue" />
              </div>
            </li>
          </ul>
          <div
          class="panel floating"
          v-if="estimatedYearlyUptimeOpen"
          >
            <h3>Update estimated uptime</h3>
              <label class="input-inline mb-2 mt-2"><input style="width: 80px;" class="text-center" type="number" v-model="estimatedYearlyUptime"> hours</label>
            <div>
              <button
              class="mb-2 mr-1"
              v-on:click="saveEstimatedUptime()"
              >Save</button>

              <button
              class="secondary mr-1"
              v-on:click="estimatedYearlyUptimeOpen = false"
              >Cancel</button>
            </div>
          </div>

          <div
          class="panel floating"
          v-if="uptimeOpen"
          >
            <h3>Update uptime</h3>
              <label class="input-inline mb-2 mt-2"><input style="width: 80px;" class="text-center" type="number" v-model="uptime.hours"> hours</label>
            <div>
              <button
              class="mb-2 mr-1"
              v-on:click="saveUptime()"
              >Save</button>

              <button
              class="secondary mr-1"
              v-on:click="uptimeOpen = false"
              >Cancel</button>
            </div>
          </div>

          <Documents :documents="specification.category.documents" />
          
        </div>

        <div 
        class="tab specification"
        v-if="currentTab === 'specification'"
        >
        
          <ul class="table-like mt-2 mb-2">
            <li
            class="tablerow-like"
            data-cellcount="2"
            v-for="(field, i) in specification.modeltype"
            v-bind:key="i"
            >
            <div class="cell-like">{{ $translate(i) }}</div><div class="cell-like">{{ field }} <span v-html="getSuffix(i)"></span></div>
            </li>
          </ul>
        </div>

        <div
        class="tab logbook"
        v-if="currentTab === 'logbook'"
        
        >
          <Logbook
          v-if="articleInstance !== {} "
          :articleInstance="articleInstance"
          :publicLogbookentries="articleInstance.logbookentries"
          />

          <div 
          class="logbook-ful"
          v-if="$store.state.loggedin"
          >
          
          </div>
          <div 
          class="logbook-ful"
          v-if="!$store.state.loggedin"
          >
            <em><span v-on:click="$parent.toggleLogin()"><u>Sign in</u></span> to view more and add logbook entries.</em>
          </div>
          
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Logbook from './Logbook.vue';
import Documents from '@/components/Documents.vue';
import Operationalstatus from '@/components/Operationalstatus.vue';
import Pencil from '@/components/Pencil.vue';

export default {
  name: 'Show',
  components: {
    Logbook,
    Documents,
    Pencil,
    Operationalstatus
  },
  data: function(){
    return {
      articleInstance: false,
      expandUptimeHistory: false,
      specification: {},
      currentTab: 'info',
      estimatedYearlyUptime: 0,
      estimatedYearlyUptimeOpen: false ,
      operationalstatus: {},
      uptime: 0,
      uptimeOpen: false,
      uptimeHistory: []
    }
  },
  methods: {
    getArticleInstance()
    {
      let self = this;
      let serialnumber = this.getSerialnumber();
      axios.get( process.env.VUE_APP_API_URL + 'serialnumber/' + serialnumber )
      .then(function(response){
        self.articleInstance = response.data.articledata;
        self.specification = response.data.specification;
        self.suffixes = response.data.suffixes;
        self.estimatedYearlyUptime = self.specification.articleinstance.estimated_yearly_uptime;
        self.articleInstanceLoaded = true;
        self.operationalstatus = response.data.operationalstatus;
        self.uptime = response.data.uptime;
        self.uptimeHistory = response.data.uptimeHistory;
      });
    },
    getSerialnumber()
    {
      return this.$route.params.serialnumber;
    },
    getSuffix(string)
    {
      if( this.suffixes[string] ){
        return this.suffixes[string];
      } else {
        return '';
      }
    },
    saveUptime(){
      let self = this;
      let serialnumber = this.getSerialnumber();

      axios.put(process.env.VUE_APP_API_URL + 'serialnumber/' + serialnumber + '/uptime', {
        uptime: self.uptime.hours,
      }).then((response) => {
        if( !response.data.error ){
          self.uptimeOpen = false;
          self.getArticleInstance();
        } else {
          console.log(response.data.error);
        }
      }).catch(error => console.log(error)); // credentials didn't match
    },
    saveEstimatedUptime(){
      let self = this;
      let serialnumber = this.getSerialnumber();

      axios.put(process.env.VUE_APP_API_URL + 'serialnumber/' + serialnumber + '/estimated_uptime', {
        uptime: self.estimatedYearlyUptime,
      }).then((response) => {
        if( !response.data.error ){
          self.specification.articleinstance.estimated_yearly_uptime = response.data.estimatedYearlyUptime;
          self.estimatedYearlyUptimeOpen = false;
          
        } else {
          console.log(response.data.error);
        }
      }).catch(error => console.log(error)); // credentials didn't match
    },
    selectTab(tab){
      this.currentTab = tab;
    }
  },
  computed: {
    headerStyle: function(){
      let style = { 
        backgroundImage: 'url("' + this.articleInstance.productarticle.productmodeltype.productmodel.productgrouptype.productgroup.productfamily.productcategory.images.main.src + '")'
      }

      return style;
    }
  },
  mounted: function(){
    this.getArticleInstance();
  }
}
</script>
<style lang="scss" scoped>

.header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  max-height: 400px;
  position: relative;
  box-shadow: 0px 0px 32px rgba(0,0,0,0.35);

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0,0.75);
  }

  > * {
    z-index: 2;
    color: #fff;
  }

  h1 {
    margin-top: 0;
  }
}

.svg-pencil {
  width: 20px;
  height: 20px;
  margin-left: 1em;
}

.content {
  position: relative;
  border-top: 3px solid var(--blue);
  background: #fff;
}

ul.tabs {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

  li {
    list-style: none;
    padding: 0.25em 0.75em;
    margin: 0 2px;
    cursor: pointer;
    border-bottom: 0;
    background: var(--blue);
    border: 3px solid var(--blue);
    border-bottom: 0px solid transparent;
    color: #fff;
    transition: all 0.15s;
    font-weight: 700;

    &.current {
      color: #3f3f3f;
      border-bottom: 0;
      background: #fff;
      border: 3px solid var(--blue);
      border-bottom: 0px solid transparent;
    }
  }

}
</style>