<template>
  <div 
  class="operational-status"
  >
    <div
    class="info-panel"
    v-on:click="toggleStatusPanel"
    >
      <img v-bind:src="statusicon" alt="">
      <p class="status-text">
        {{ $translate( status.status ) }}
      </p>
      <p>
        {{ $translate('next_periodiccontrol_last_date') }}: {{ status.due_on }}
      </p>
    </div>
    <div 
    class="status-panel"
    v-bind:class="{'open': panelOpen}"
    v-if="$store.state.loggedin"
    >
      <button
      v-if="!submitted"
      v-on:click="performControl"
      >{{ $translate('confirm_periodiccontrol') }}</button>
      <button
      class="secondary"
      v-if="!submitted"
      v-on:click="toggleStatusPanel"
      >{{ $translate('cancel_periodiccontrol') }}</button>

      <p>{{ notification }}</p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Pencil',
  props: {
    status: Object,
    statusText: String,
    dateText: String,
    buttonText: String
  },
  data: function(){
    return {
      statusicon: '',
      panelOpen: false,
      submitted: false,
      notification: ''
    }
  },
  methods: {
    handleClick(){
      
      // this.$emit('click');
    },
    toggleStatusPanel(){
      this.panelOpen = !this.panelOpen;
    },
    performControl(){
      let self = this;
      self.submitted = true;
      self.notification = 'Please wait...';
      setTimeout(function(){
        self.submitted = false;
      }, 6000);
      axios.post(process.env.VUE_APP_API_URL + 'serialnumber/' + this.$route.params.serialnumber + '/periodiccontrol', {}).then(() => {
        self.$emit('reload');
        self.notification = 'Saved!';
        setTimeout(function(){
          self.notification = '';
          self.panelOpen = false;
        }, 3000);
      }).catch(error => console.log(error));
    }
  },
  mounted: function(){
    if( this.status.operational ){
      this.statusicon = require('@/assets/icon-ok.svg');
    } else {
      this.statusicon = require('@/assets/icon-error.svg');
    }
  },
  watch: {
    status: function(){
      if( this.status.operational ){
        this.statusicon = require('@/assets/icon-ok.svg');
      } else {
        this.statusicon = require('@/assets/icon-error.svg');
      }
    }
  }
}
</script>
<style scoped>
  .operational-status {
    position: relative;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 80px;
    height: 80px;
  }
  p {
    font-size: 13px;
    margin: 10px;
  }
  .status-panel {
    max-height: 0px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    right: 0px;
    background: #fff;
    padding: 0px;
    width: 200px;
    transform: translateX(-50%);
    color: #3f3f3f;
  }
  .status-panel.open {
    max-height: 100px;
    padding: 10px 0px;
  }
  .status-panel button {
    width: 180px;
    margin: 5px 10px;
  }
</style>