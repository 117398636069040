<template>
  <div class="section documents">
    <h3>Documents</h3>
    <ul>
      <li
      v-for="(documentCategory, ci) in documents"
      v-bind:key="ci"
      >
        <ul>
          <li
          v-for="(document, i) in documentCategory"
          v-bind:key="i"
          >
            <div class="file-icon">{{ document.filetype }}</div>
            <a target="_blank" v-bind:href="document.url">{{ document.originalfilename }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>  
</template>
<script>
export default {
  name: 'Documents',
  props: {
    documents: Object
  }

}
</script>
<style lang="scss" scoped>
div.documents {
  padding: 0 10px;
  text-align: left;
}
ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    text-align: left;

    ul {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;

        .file-icon {
          display: inline-block;
          margin-right: 5px;
          width: 25px;
          height: 30px;
          background: var(--blue);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          border-radius: 2px;
          color: #fff;
        }

        a {
          color: var(--blue);
          text-decoration: none;;
        }
      }
    }
  }
}
</style>