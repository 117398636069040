<template>
  <div id="app">
    <div id="tophat">
      <div class="navigation">
        
        <router-link 
        to="/list"
        v-if="$store.state.loggedin"
        >List</router-link>
        
        <router-link to="/search">Search</router-link>
      </div>
      <button
      class="log-out"
      v-on:click="logout()"
      v-if="$store.state.loggedin"
      >Log out</button>
      <button
      class="log-in"
      v-on:click="toggleLogin()"
      v-if="!$store.state.loggedin"
      >
      <span v-if="!loginOpen">Log in</span>
      <span v-if="loginOpen">Cancel</span>
      </button>
    </div>
    <Login 
      v-if="!$store.state.loggedin && loginOpen"
      />
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';
import Login from '@/components/Login.vue'

export default {
  components: {
    Login
  },
  data: function(){
    return {
      loginOpen: false
    }
  },
  methods: {
    isUserAuthenticated()
    {
      let self = this;
      axios.get(process.env.VUE_APP_API_URL + 'app/boot')
      .then(function(response){
        self.$store.translations = response.data.translations;
        
        if( response.data.authenticated ){
          self.$store.state.loggedin = true;
        }
      });
        
    },
    logout()
    {
      let self = this;
      axios.get(process.env.VUE_APP_API_URL + 'app/logout')
      .then(function(){
        self.$store.state.loggedin = false;
      });
    },
    toggleLogin()
    {
      this.loginOpen = !this.loginOpen;
    }
    
  },
  mounted: function(){
    this.isUserAuthenticated();
  },
}
</script>

<style lang="scss">
:root {
  --blue: #009fdd;
  --blue-dark: #0079a9;
  --blue-highlight: #53c9f7;
  --white: #ffffff;
  --grey: #666;
  --tablecell-border: #ebebeb;
  --shadow: 0px 0px 32px rgba(0,0,0,0.25);
}

body {
  margin: 0;
}

#app {
  font-family: "Saira", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;

  * {
    box-sizing: border-box;
  }
}

#tophat {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background: rgba(255,255,255,0.85);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;
  z-index: 100;

  .navigation {
    display: flex;
    justify-content: center;

    > * {
      margin-right: 1em;
    }
  }

  a {
    text-decoration: none;
    color: var(--blue);

    &.router-link-exact-active {
      color: var(--blue-dark);
    }
  }

  .log-in, .log-out {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 14px;
    height: 30px;
  }
}

h1, h2, h3 {
  margin-top: 0;
}

.mb-1 {
  margin-bottom: 5px;
}
.mt-1 {
  margin-top: 5px;
}
.ml-1 {
  margin-left: 5px;
}
.mr-1 {
  margin-right: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}
.mt-2 {
  margin-top: 10px;
}
.ml-2 {
  margin-left: 10px;
}
.mr-2 {
  margin-right: 10px;
}
.text-center {
  text-align: center;
}

input, textarea {
  border: 1px solid var(--blue);
  padding: 0.25em 0.5em;
  font-family: "Saira", Helvetica, Arial, sans-serif;
  font-size: 16px;

  &:focus {
    outline: none;
    border: 1px solid var(--blue-dark);
  }
}

a.button {
  display: inline-block;
}

button, .button {
  background: var(--blue);
  border: none;
  border: 2px solid var(--blue);
  color: var(--white);
  font-size: 16px;
  padding: 0.25em 0.5em;
  outline: 1px solid transparent;
  transition: filter 0.25s;
  text-decoration: none;


  &:hover {
    filter: brightness(1.2);
  }

  &:focus {
    outline: none;
    border: 2px solid var(--blue-highlight)
  }

  &:disabled {
    border: 2px solid var(--blue-highlight);
    background: var(--blue-highlight);
    cursor: not-allowed;
    color: #ebebeb;

    &:hover {
      outline: 1px solid transparent;
    }
  }

  &.secondary {
    background: var(--grey);
    border: 2px solid var(--grey);
  }
}

.panel {
  width: auto;
  display: inline-block;
  margin: auto;
  box-shadow: var(--shadow);
  padding: 10px;
  min-width: 220px;

  &.floating {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
  }

  h2, h3 {
    margin: 0 0 10px;
  }

  .input-inline {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    
    input {
      margin-right: 0.5em;
    }
  }
}

.content {
  max-width: 1044px;
  margin: auto;
  box-shadow: var(--shadow);
  padding-bottom: 80px;
}

.svg-blue {
  fill: var(--blue);
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.app-link {
  text-decoration: underline;
  color: var(--blue);
}

.contracted {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s;

  &.expanded {
    max-height: 50vh;
    overflow: scroll;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.25) inset;
  }
}

ul.table-like {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 14px;

  * {
    font-size: 14px;
  }

  li.tablerow-like {
    display: flex;

    &[data-cellcount="2"] {

      div.cell-like {
        width: 50%;
        padding: 0.5em;
        border-bottom: 1px solid var(--tablecell-border);
        display: flex;

        &:nth-child(1){
          justify-content: flex-end;
          align-items: center;
          align-content: flex-end;
          text-align: right;
          border-right: 1px solid var(--tablecell-border);
        }
        &:nth-child(2){
          justify-content: flex-start;
          align-items: center;
          align-content: flex-start;
          text-align: left;
        }

        button, .button {
          margin-top: 0;
        }
      }
    }

    div.cell-like {
      padding: 0.25em;
    }
  }
}

</style>
